<template>
  <div class="form-inline dt-bootstrap no-footer">
    <posts-filter />
    <div class="row">
      <div class="col-lg-12">
        <div v-if="!!posts.length" class="row">
          <post-snippet v-for="post in posts" :key="post.id" :post="post" />
        </div>
      </div>
    </div>
    <posts-pagination v-if="!!posts.length" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import tripcode from "tripcode";
import PostsFilter from "@/components/PostsFilter.vue";
import PostSnippet from "@/components/PostSnippet.vue";
import PostsPagination from "@/components/PostsPagination.vue";

export default {
  components: {
    PostsFilter,
    PostSnippet,
    PostsPagination
  },
  computed: mapState(["posts"]),
  mounted() {
    this.$store.commit("page", "0");
    this.$store.dispatch("reloadPosts");
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      if (localStorage.getItem("flag")) {
        localStorage.removeItem("flag");
        window.location.reload(true);
      }
    }
  }
};
</script>
