<template>
  <div v-if="post" class="col-lg-12">
    <div class="grid-container">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="grid-container">
              <div class="row" style="display:flex;">
                <div class="col-lg-12" style="display:flex;">
                  <div class="col-lg-4">
                    <div
                      v-if="
                        typeof post.images !== 'undefined' &&
                          post.images.length > 0
                      "
                    >
                      <img
                        :src="'/assets/img/' + post.images[0]"
                        alt=""
                        style="object-fit: contain; display: block; margin: 0 auto;max-width: 260px;height: 170px;"
                      />
                    </div>
                    <img
                      v-else
                      src="/assets/img/default.png"
                      alt="画像なし"
                      style="object-fit: contain; display: block; margin: 0 auto;max-width: 260px;height: 170px;"
                    />
                  </div>
                  <div class="col-lg-8">
                    <h1 class="h2">
                      <router-link
                        :to="{ name: 'post', params: { postID: post.id } }"
                        >{{ post.title }}</router-link
                      >
                    </h1>
                    <p style="margin-top:20px">
                      コメント: {{ post.comments }} 件
                    </p>
                  </div>
                </div>
                <div class="col-lg-4" style="margin-top:20px">
                  <div v-if="post.status === '1'" class="col-lg-6 container">
                    <button class="btn btn-open waves-effect waves-light">
                      オープン
                    </button>
                  </div>
                  <div v-else class="col-lg-6 container">
                    <button class="btn btn-close waves-effect waves-light">
                      クローズ
                    </button>
                  </div>
                  <div class="col-lg-6 container">
                    <p>掲載開始: {{ post.posted_on }}</p>
                    <p>受付終了: {{ post.closed_by }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    post: Object
  }
};
</script>
