<template>
  <div v-if="post" class="form-inline dt-bootstrap no-footer">
    <div class="row">
      <div class="col-lg-6">
        <h1 class="h2">{{ post.title }}</h1>
      </div>
      <div v-if="post.status === '1'" class="col-lg-6 text-right">
        <button class="btn btn-open waves-effect waves-light">オープン</button>
      </div>
      <div v-else class="col-lg-6 text-right">
        <button class="btn btn-close waves-effect waves-light">クローズ</button>
      </div>
    </div>
    <div class="row item-bottom">
      <div class="col-lg-6">
        <p>コメント: {{ comments.length }} 件</p>
      </div>
      <div class="col-lg-6 text-right">
        <p>掲載開始: {{ post.posted_on }}</p>
        <p>受付終了: {{ post.closed_by }}</p>
      </div>
    </div>

    <div class="row" id="post-main">
      <div class="col-lg-6">
        <div
          v-if="typeof post.images !== 'undefined' && post.images.length > 0"
          id="item-photos"
          data-ride="carousel"
          class="carousel slide"
        >
          <ol class="carousel-indicators">
            <li
              v-for="(_, index) in post.images"
              :key="index"
              data-target="#item-photos"
              :data-slide-to="index"
              :class="{ active: index === 0 }"
            ></li>
          </ol>
          <div role="listbox" class="carousel-inner">
            <div
              v-for="(image, index) in post.images"
              :key="index"
              :class="{ item: true, active: index === 0 }"
            >
              <img
                :src="'/assets/img/' + image"
                alt=""
                style="object-fit: contain; display: block; margin: 0 auto; width: 600px; height: 420px;"
              />
            </div>
          </div>
          <a
            href="#item-photos"
            data-slide="prev"
            class="left carousel-control"
            role="button"
          >
            <span aria-hidden="true" class="fa fa-angle-left"></span>
            <span class="sr-only">前へ</span>
          </a>
          <a
            href="#item-photos"
            data-slide="next"
            class="right carousel-control"
            role="button"
          >
            <span aria-hidden="true" class="fa fa-angle-right"></span>
            <span class="sr-only">次へ</span>
          </a>
        </div>
        <img
          v-else
          src="/assets/img/default.png"
          alt="画像なし"
          style="object-fit: contain; display: block; margin: 0 auto; width: 600px; height: 420px;"
        />
      </div>

      <div class="col-lg-6">
        <div style="width: 90%;margin: auto;">
          <table class="table table-striped m-0">
            <tbody>
              <tr>
                <th>名前</th>
                <td>{{ post.name }}</td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>{{ post.email }}</td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td>{{ post.tel }}</td>
              </tr>
              <tr>
                <th>カテゴリー</th>
                <td v-if="post.category == '0'">研究設備</td>
                <td v-if="post.category == '1'">研究機器</td>
                <td v-if="post.category == '2'">什器</td>
                <td v-if="post.category == '3'">その他</td>
              </tr>
              <tr>
                <th>メーカー</th>
                <td>{{ post.manufacturer }}</td>
              </tr>
              <tr>
                <th>型番</th>
                <td>{{ post.model }}</td>
              </tr>
              <tr>
                <th>状態</th>
                <td>{{ post.condition }}</td>
              </tr>
              <tr>
                <th>キャンパス・建物</th>
                <td>{{ post.location }}</td>
              </tr>
              <tr>
                <th>引き渡し時期</th>
                <td>{{ post.schedule }}</td>
              </tr>
            </tbody>
          </table>
          <div class="input-group m-t-20">
            <form>
              <input
                ref="pass"
                @input="clearAuth"
                type="password"
                placeholder="パスワード"
                class="form-control"
                required
              />
              <span class="input-group-btn">
                <button
                  @click="edit($event)"
                  class="btn waves-effect waves-light btn-purple"
                >
                  編集
                </button>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="post-desc" style="margin-top: 30px;">
      <div class="col-lg-12">
        <p v-html="description">{{ post.description }}</p>
      </div>
    </div>

    <comment-list v-if="comments.length > 0" :post="post.id" />
    <comment-field :post="post.id" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import tripcode from "tripcode";
import CommentList from "@/components/CommentList.vue";
import CommentField from "@/components/CommentField.vue";

export default {
  components: {
    CommentList,
    CommentField
  },
  data() {
    return {
      authed: false
    };
  },
  computed: {
    ...mapState(["post", "comments"]),
    description() {
      if (typeof this.post.description !== "undefined") {
        return this.post.description.replace(/\n/g, "<br>");
      }
      return "";
    }
  },
  methods: {
    clearAuth() {
      this.authed = false;
    },
    edit(e) {
      if (this.$refs.pass.checkValidity()) {
        e.preventDefault();

        if (this.authed) {
          window.$(".form-modal").modal("show");
        } else {
          let body = new FormData();
          body.append("function", "MATCH");
          body.append("id", this.post.id);
          body.append("pass", tripcode(this.$refs.pass.value));
          axios
            .post("/api/posts.php", body)
            .then(res => {
              if (res.data.data) {
                this.authed = true;
                window.$(".form-modal").modal("show");
              } else {
                window.alert("正しいパスワードを入力してください。");
              }
            })
            .catch(err => {
              window.console.log(err);
            });
        }
      }
    }
  },
  beforeMount() {
    this.$store.dispatch("reloadPost", this.$route.params.postID);
    this.$store.dispatch("reloadComments", this.$route.params.postID);
  },
  beforeDestroy() {
    this.$store.commit("post", {});
    this.$store.commit("comments", []);
  }
};
</script>
