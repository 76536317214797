import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: "0",
    category: "",
    status: "",
    keyword: "",
    limit: 25,
    page: "0",
    posts: [],
    post: {},
    comments: []
  },
  mutations: {
    count(state, data) {
      state.count = data;
    },
    category(state, data) {
      state.category = data;
    },
    status(state, data) {
      state.status = data;
    },
    keyword(state, data) {
      state.keyword = data;
    },
    limit(state, data) {
      state.limit = parseInt(data);
    },
    page(state, data) {
      state.page = data;
    },
    posts(state, data) {
      state.posts = data;
    },
    post(state, data) {
      state.post = data;
    },
    comments(state, data) {
      state.comments = data;
    }
  },
  actions: {
    reloadPosts({ commit }) {
      axios
        .get("/api/posts.php", {
          params: {
            cat: this.state.category,
            stat: this.state.status,
            q: this.state.keyword,
            lim: this.state.limit,
            p: this.state.page
          }
        })
        .then(res => {
          commit("count", res.data.count);
          commit("posts", res.data.data);
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    reloadPost({ commit }, id) {
      axios
        .get("/api/posts.php", {
          params: {
            id: id
          }
        })
        .then(res => {
          commit("post", res.data.data);
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    reloadComments({ commit }, id) {
      axios
        .get("/api/comments.php", {
          params: {
            post_id: id
          }
        })
        .then(res => {
          commit("comments", res.data.data);
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    deletePost({ dispatch }, id) {
      let body = new FormData();
      body.append("function", "DELETE");
      body.append("id", id);
      axios
        .post("/api/posts.php", body)
        .then(() => {
          dispatch("reloadPosts");
          window.location.href = "/";
        })
        .catch(err => {
          window.console.log(err);
        });
    }
  }
});
