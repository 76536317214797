<template>
  <header>
    <div class="topbar">
      <div class="topbar-left">
        <div class="text-center">
          <router-link :to="{ name: 'home' }" class="logo"
            >研究設備リユース掲示板</router-link
          >
        </div>
      </div>
      <div class="navbar navbar-default" role="navigation">
        <div class="container">
          <div v-if="isHome" class="pull-left button-holder">
            <button
              class="btn btn-post waves-effect waves-light"
              data-toggle="modal"
              data-target=".form-modal"
            >
              <span class="btn-label"><i class="ion-plus"></i></span>新規投稿
            </button>
          </div>
          <div v-else class="pull-left button-holder">
            <router-link
              :to="{ name: 'home' }"
              class="btn btn-link waves-effect"
              >トップページに戻る</router-link
            >
          </div>
          <div
            v-if="isHome"
            class="navbar-left app-search pull-left hidden-xs"
            role="search"
          >
            <input
              ref="keyword"
              @keyup.enter="changeKeyword"
              placeholder="検索する"
              class="form-control"
            />
            <a @click.prevent="changeKeyword" role="button"
              ><i class="fa fa-search"></i
            ></a>
          </div>
          <div class="nav navbar-nav navbar-right pull-right button-holder">
            <a
              class="btn btn-link waves-effect"
              href="/guide.pdf"
              target="_blank"
              >サイトの使い方</a
            >
            <a
              class="btn btn-link waves-effect"
              href="/rules.pdf"
              target="_blank"
              >利用規約</a
            >
            <div v-if="isHome" class="btn-group">
              <button
                @click="changeByCategory"
                data-category=""
                class="btn btn-white waves-effect"
              >
                すべて
              </button>
              <button
                @click="changeByCategory"
                data-category="0"
                class="btn btn-white waves-effect"
              >
                研究設備
              </button>
              <button
                @click="changeByCategory"
                data-category="1"
                class="btn btn-white waves-effect"
              >
                研究機器
              </button>
              <button
                @click="changeByCategory"
                data-category="2"
                class="btn btn-white waves-effect"
              >
                什器
              </button>
              <button
                @click="changeByCategory"
                data-category="3"
                class="btn btn-white waves-effect"
              >
                その他
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    reload() {
      this.$store.commit("page", "0");
      this.$store.dispatch("reloadPosts");
    },
    changeKeyword() {
      this.$store.commit("keyword", this.$refs.keyword.value);
      this.reload();
    },
    changeByCategory(e) {
      this.$store.commit("category", e.target.dataset.category);
      this.reload();
    }
  },
  computed: {
    isHome() {
      if (this.$route.path === "/") {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
.button-holder {
  margin: 12px 0px 12px 10px;
}
.btn-link {
  color: #fff !important;
}
.form-control,
.form-control:focus {
  font-weight: 400;
}
</style>
