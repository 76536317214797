<template>
  <vue-ads-pagination
    :max-visible-pages="3"
    :total-items="count"
    :items-per-page="limit"
    :page="page"
    :loading="loading"
    @range-change="updateRange"
    class="row"
  >
    <template slot-scope="props">
      <div class="col-lg-6">
        <div class="pageinfo" role="status" aria-live="polite">
          {{ props.total }} 件中 {{ props.start }} から
          {{ props.end }} 件目を表示
        </div>
      </div>
    </template>
    <template slot="buttons" slot-scope="props">
      <div class="col-lg-6 text-right pagination">
        <vue-ads-page-button
          v-for="(button, key) in props.buttons"
          :key="key"
          v-bind="button"
          @page-change="page = button.page"
        />
      </div>
    </template>
  </vue-ads-pagination>
</template>

<script>
import { mapState } from "vuex";
import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";

export default {
  components: {
    VueAdsPagination,
    VueAdsPageButton
  },
  data() {
    return {
      loading: false,
      page: 0,
      start: 0,
      end: 0
    };
  },
  computed: mapState(["count", "limit"]),
  methods: {
    updateRange(start, end) {
      this.$store.commit("page", this.page);
      this.$store.dispatch("reloadPosts");
    }
  }
};
</script>
