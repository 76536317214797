<template>
  <div class="row filter">
    <div class="col-lg-6">
      <div>
        <label>
          <select @change="changeLimit" class="form-control input-sm">
            <option value="10" :selected="limit === 10">10</option>
            <option value="25" :selected="limit === 25">25</option>
            <option value="50" :selected="limit === 50">50</option>
          </select>
          件ずつ表示
        </label>
      </div>
    </div>
    <div v-if="isAdmin" class="col-lg-6 text-right">
      <div class="input-group">
        <input
          ref="keyword"
          @keyup.enter="changeKeyword"
          class="form-control"
          placeholder="検索する"
        />
        <span class="input-group-btn">
          <button
            @click="changeKeyword"
            class="btn waves-effect waves-light btn-default"
          >
            検索
          </button>
        </span>
      </div>
    </div>
    <div v-else class="col-lg-6 text-right">
      <a
        @click.prevent="changeStatus"
        data-status=""
        class="btn btn-link waves-effect"
        role="button"
        >すべて表示</a
      >
      <a
        @click.prevent="changeStatus"
        data-status="1"
        class="btn btn-link waves-effect"
        role="button"
        >オープンのみ</a
      >
      <a
        href="/api/rss.php"
        target="_blank"
        class="btn btn-rss waves-effect waves-light"
        role="button"
        >RSS</a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isAdmin: Boolean
  },
  computed: mapState(["limit"]),
  methods: {
    reload() {
      this.$store.commit("page", "0");
      this.$store.dispatch("reloadPosts");
    },
    changeLimit(e) {
      this.$store.commit("limit", e.target.value);
      this.reload();
    },
    changeKeyword() {
      this.$store.commit("keyword", this.$refs.keyword.value);
      this.reload();
    },
    changeStatus(e) {
      this.$store.commit("status", e.target.dataset.status);
      this.reload();
    }
  }
};
</script>
