<template>
  <div
    class="modal fade form-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" data-dismiss="modal" aria-hidden="true">
            ×
          </button>
          <h4 v-if="isNew" class="modal-title" id="myLargeModalLabel">
            新規投稿
          </h4>
          <h4 v-else class="modal-title" id="myLargeModalLabel">編集</h4>
        </div>
        <div class="modal-body">
          <div class="col-lg-12">
            <form
              class="form-horizontal"
              method="POST"
              enctype="multipart/form-data"
              role="form"
              @submit.prevent="submit"
            >
              <input
                v-if="!isNew"
                ref="id"
                type="hidden"
                name="id"
                :value="post.id"
              />

              <!-- Name -->
              <div class="form-group">
                <label class="col-md-2 control-label"
                  >名前 <span class="text-danger">※</span></label
                >
                <div class="col-md-10">
                  <input
                    ref="name"
                    name="name"
                    :value="!isNew ? post.name : ''"
                    class="form-control form-val"
                    required
                  />
                </div>
              </div>

              <!-- Password -->
              <input
                v-if="isAdmin"
                ref="newpass"
                type="hidden"
                name="pass"
                :value="post.pass"
              />
              <input
                v-if="!isNew"
                ref="newpass1"
                type="hidden"
                name="pass"
                :value="post.pass"
              />
              <div v-if="isNew || isAdmin" class="form-group">
                <label class="col-md-2 control-label"
                  >パスワード <span class="text-danger">※</span></label
                >
                <div class="col-md-10">
                  <input
                    name="pass"
                    ref="pass"
                    class="form-control form-val"
                    :required="isNew"
                  />
                </div>
              </div>

              <!-- Status -->
              <div v-if="!isNew" class="form-group">
                <label class="col-md-2 control-label">ステータス</label>
                <div class="col-md-10">
                  <label class="radio_container"
                    >オープン
                    <input
                      type="radio"
                      name="status"
                      ref="status"
                      value="1"
                      :checked="!isNew && post.status === '1'"
                      @change="onChange($event)"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio_container"
                    >クローズ
                    <input
                      type="radio"
                      name="status"
                      ref="status"
                      value="0"
                      :checked="!isNew && post.status === '0'"
                      @change="onChange($event)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <!-- Title -->
              <div class="form-group">
                <label class="col-md-2 control-label"
                  >タイトル <span class="text-danger">※</span></label
                >
                <div class="col-md-10">
                  <input
                    name="title"
                    ref="title"
                    :value="!isNew ? post.title : ''"
                    class="form-control form-val"
                    required
                  />
                </div>
              </div>

              <!-- Email -->
              <div class="form-group">
                <label class="col-md-2 control-label"
                  >メールアドレス <span class="text-danger">※</span></label
                >
                <div class="col-md-10">
                  <input
                    type="email"
                    name="email"
                    ref="email"
                    :value="!isNew ? post.email : ''"
                    class="form-control form-val"
                    required
                  />
                </div>
              </div>

              <!-- Phone -->
              <div class="form-group">
                <label class="col-md-2 control-label">電話番号</label>
                <div class="col-md-10">
                  <input
                    ref="tel"
                    type="phone"
                    name="tel"
                    :value="!isNew ? post.tel : ''"
                    class="form-control form-val"
                  />
                </div>
              </div>

              <!-- Category -->
              <div class="form-group">
                <label class="col-sm-2 control-label"
                  >カテゴリー <span class="text-danger">※</span></label
                >
                <div class="col-sm-10">
                  <select
                    name="category"
                    ref="category"
                    class="form-control form-val"
                    required
                  >
                    <option value="">-</option>
                    <option
                      value="0"
                      :selected="!isNew && post.category === '0'"
                      >研究設備</option
                    >
                    <option
                      value="1"
                      :selected="!isNew && post.category === '1'"
                      >研究機器</option
                    >
                    <option
                      value="2"
                      :selected="!isNew && post.category === '2'"
                      >什器</option
                    >
                    <option
                      value="3"
                      :selected="!isNew && post.category === '3'"
                      >その他</option
                    >
                  </select>
                </div>
              </div>

              <!-- Manufacturer -->
              <div class="form-group">
                <label class="col-md-2 control-label">メーカー</label>
                <div class="col-md-10">
                  <input
                    name="manufacturer"
                    ref="manufacturer"
                    :value="!isNew ? post.manufacturer : ''"
                    class="form-control form-val"
                  />
                </div>
              </div>

              <!-- Model Number -->
              <div class="form-group">
                <label class="col-md-2 control-label">型番</label>
                <div class="col-md-10">
                  <input
                    name="model"
                    ref="model"
                    :value="!isNew ? post.model : ''"
                    class="form-control form-val"
                  />
                </div>
              </div>

              <!-- Condition -->
              <div class="form-group">
                <label class="col-md-2 control-label">状態</label>
                <div class="col-md-10">
                  <input
                    ref="condition"
                    name="condition"
                    :value="!isNew ? post.condition : ''"
                    class="form-control form-val"
                  />
                </div>
              </div>

              <!-- Location -->
              <div class="form-group">
                <label class="col-md-2 control-label">キャンパス・建物</label>
                <div class="col-md-10">
                  <input
                    ref="location"
                    name="location"
                    :value="!isNew ? post.location : ''"
                    class="form-control form-val"
                  />
                </div>
              </div>

              <!-- Delivery time -->
              <div class="form-group">
                <label class="col-md-2 control-label"
                  >引き渡し時期 <span class="text-danger">※</span></label
                >
                <div class="col-md-10">
                  <input
                    name="schedule"
                    :value="!isNew ? post.schedule : ''"
                    class="form-control form-val"
                    ref="schedule"
                    required
                  />
                </div>
              </div>

              <!-- Validity -->
              <div class="form-group">
                <label class="control-label col-md-2"
                  >受付期限 <span class="text-danger">※</span></label
                >
                <div class="col-md-10">
                  <div class="input-group">
                    <input
                      id="datepicker-autoclose"
                      name="closed_by"
                      ref="closed_by"
                      :value="!isNew ? post.closed_by : ''"
                      class="form-control form-val"
                      required
                    />
                    <span
                      @click="showCalendar"
                      class="input-group-addon bg-custom b-0 text-white"
                      ><i class="icon-calender"></i
                    ></span>
                  </div>
                </div>
              </div>

              <!-- Images 1 -->
              <div class="form-group">
                <label class="control-label col-md-2">画像1</label>
                <div class="col-md-10">
                  <div
                    v-if="
                      typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img1./)
                    "
                  >
                    <a
                      :href="
                        '/assets/img/' +
                          post.images.toString().match(/\w+_img1.\w+/)[0]
                      "
                      target="_blank"
                      class="vertical-middle m-r-10"
                      ><img
                        :src="
                          '/assets/img/' +
                            post.images.toString().match(/\w+_img1.\w+/)[0]
                        "
                        alt=""
                        height="50"
                    /></a>

                    <button
                      @click="toggleFile"
                      type="button"
                      class="btn btn-default waves-effect waves-light"
                    >
                      変更
                    </button>
                  </div>
                  <div
                    :class="{
                      'display-none':
                        typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img1./)
                    }"
                    class="bootstrap-filestyle input-group"
                  >
                    <input
                      type="file"
                      name="img1"
                      ref="img1"
                      class="filestyle"
                      data-buttonbefore="true"
                    />
                  </div>
                </div>
              </div>

              <!-- Images 2 -->
              <div class="form-group">
                <label class="control-label col-md-2">画像2</label>
                <div class="col-md-10">
                  <div
                    v-if="
                      typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img2./)
                    "
                  >
                    <a
                      :href="
                        '/assets/img/' +
                          post.images.toString().match(/\w+_img2.\w+/)[0]
                      "
                      target="_blank"
                      class="vertical-middle m-r-10"
                      ><img
                        :src="
                          '/assets/img/' +
                            post.images.toString().match(/\w+_img2.\w+/)[0]
                        "
                        alt=""
                        height="50"
                    /></a>

                    <button
                      @click="toggleFile"
                      type="button"
                      class="btn btn-default waves-effect waves-light"
                    >
                      変更
                    </button>
                  </div>
                  <div
                    :class="{
                      'display-none':
                        typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img2./)
                    }"
                    class="bootstrap-filestyle input-group"
                  >
                    <input
                      type="file"
                      name="img2"
                      class="filestyle"
                      data-buttonbefore="true"
                      ref="img2"
                    />
                  </div>
                </div>
              </div>

              <!-- Images 3 -->
              <div class="form-group">
                <label class="control-label col-md-2">画像3</label>
                <div class="col-md-10">
                  <div
                    v-if="
                      typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img3./)
                    "
                  >
                    <a
                      :href="
                        '/assets/img/' +
                          post.images.toString().match(/\w+_img3.\w+/)[0]
                      "
                      target="_blank"
                      class="vertical-middle m-r-10"
                      ><img
                        :src="
                          '/assets/img/' +
                            post.images.toString().match(/\w+_img3.\w+/)[0]
                        "
                        alt=""
                        height="50"
                    /></a>

                    <button
                      @click="toggleFile"
                      type="button"
                      class="btn btn-default waves-effect waves-light"
                    >
                      変更
                    </button>
                  </div>
                  <div
                    :class="{
                      'display-none':
                        typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img3./)
                    }"
                    class="bootstrap-filestyle input-group"
                  >
                    <input
                      type="file"
                      name="img3"
                      class="filestyle"
                      data-buttonbefore="true"
                      ref="img3"
                    />
                  </div>
                </div>
              </div>

              <!-- Images 4 -->
              <div class="form-group">
                <label class="control-label col-md-2">画像4</label>
                <div class="col-md-10">
                  <div
                    v-if="
                      typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img4./)
                    "
                  >
                    <a
                      :href="
                        '/assets/img/' +
                          post.images.toString().match(/\w+_img4.\w+/)[0]
                      "
                      target="_blank"
                      class="vertical-middle m-r-10"
                      ><img
                        :src="
                          '/assets/img/' +
                            post.images.toString().match(/\w+_img4.\w+/)[0]
                        "
                        alt=""
                        height="50"
                    /></a>

                    <button
                      @click="toggleFile"
                      type="button"
                      class="btn btn-default waves-effect waves-light"
                    >
                      変更
                    </button>
                  </div>
                  <div
                    :class="{
                      'display-none':
                        typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img4./)
                    }"
                    class="bootstrap-filestyle input-group"
                  >
                    <input
                      type="file"
                      name="img4"
                      class="filestyle"
                      data-buttonbefore="true"
                      ref="img4"
                    />
                  </div>
                </div>
              </div>

              <!-- Images 5 -->
              <div class="form-group">
                <label class="control-label col-md-2">画像5</label>
                <div class="col-md-10">
                  <div
                    v-if="
                      typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img5./)
                    "
                  >
                    <a
                      :href="
                        '/assets/img/' +
                          post.images.toString().match(/\w+_img5.\w+/)[0]
                      "
                      target="_blank"
                      class="vertical-middle m-r-10"
                      ><img
                        :src="
                          '/assets/img/' +
                            post.images.toString().match(/\w+_img5.\w+/)[0]
                        "
                        alt=""
                        height="50"
                    /></a>

                    <button
                      @click="toggleFile"
                      type="button"
                      class="btn btn-default waves-effect waves-light"
                    >
                      変更
                    </button>
                  </div>
                  <div
                    :class="{
                      'display-none':
                        typeof post.images !== 'undefined' &&
                        post.images.toString().match(/_img5./)
                    }"
                    class="bootstrap-filestyle input-group"
                  >
                    <input
                      type="file"
                      name="img5"
                      class="filestyle"
                      data-buttonbefore="true"
                      ref="img5"
                    />
                  </div>
                </div>
              </div>

              <!-- Description -->
              <div class="form-group">
                <label class="col-md-2 control-label">概要</label>
                <div class="col-md-10">
                  <textarea
                    rows="5"
                    name="description"
                    ref="description"
                    :value="!isNew ? post.description : ''"
                    class="form-control form-val"
                  ></textarea>
                </div>
              </div>

              <!-- Submit -->
              <div class="form-group text-center">
                <button
                  type="submit"
                  class="btn btn-default waves-effect waves-light"
                >
                  送信
                </button>
                <button
                  v-if="!isNew && !isAdmin"
                  @click.prevent="deletePost(post.id)"
                  class="btn btn-danger"
                  style="margin-left: 20px;background-color: #dc3545;color: #fff;"
                >
                  削除
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
import { mapState } from "vuex";
import tripcode from "tripcode";
import axios from "axios";

export default {
  props: {
    post: String
  },
  computed: {
    ...mapState(["post"]),
    isNew() {
      if (this.$route.path === "/") {
        return true;
      }
      return false;
    },
    isAdmin() {
      if (this.$route.path === "/manage") {
        return true;
      }
      return false;
    }
  },
  data: function() {
    return {
      passvalue: "",
      files: [],
      statusval: ""
    };
  },
  methods: {
    onChange(event) {
      this.statusval = event.target.value;
    },
    deletePost(id) {
      if (confirm("削除しますか？")) {
        this.$store.dispatch("deletePost", id);
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
          localStorage.setItem("flag", true);
        }
      }
    },
    submit() {
      let body = new FormData();
      if (this.statusval === "") {
        this.statusval = this.post.status;
      }
      if (typeof this.$refs.newpass != "undefined") {
        body.append("id", this.$refs.id.value);
        if (this.$refs.pass.value == "") {
          this.passvalue = this.$refs.newpass.value;
          body.append("pass", this.passvalue);
          body.append("status", this.statusval);
          body.append("function", "PUTADMIN");
        } else {
          this.passvalue = tripcode(this.$refs.pass.value);
          body.append("status", this.statusval);
          body.append("pass", this.passvalue);
          body.append("function", "PUTADMIN");
        }
      } else {
        if (typeof this.$refs.pass == "undefined") {
          body.append("function", "PUT");
          body.append("pass", this.$refs.newpass1.value);
          body.append("id", this.$refs.id.value);
          body.append("status", this.statusval);
        } else {
          body.append("function", "POST");
          this.$refs.pass.value = tripcode(this.$refs.pass.value);
          body.append("pass", this.$refs.pass.value);
        }
      }
      body.append("name", this.$refs.name.value);
      body.append("title", this.$refs.title.value);
      body.append("category", this.$refs.category.value);
      body.append("email", this.$refs.email.value);
      body.append("tel", this.$refs.tel.value);
      body.append("manufacturer", this.$refs.manufacturer.value);
      body.append("model", this.$refs.model.value);
      body.append("condition", this.$refs.condition.value);
      body.append("location", this.$refs.location.value);
      body.append("schedule", this.$refs.schedule.value);
      body.append("closed_by", this.$refs.closed_by.value);
      body.append("description", this.$refs.description.value);
      body.append("img1", this.$refs.img1.files[0]);
      body.append("img2", this.$refs.img2.files[0]);
      body.append("img3", this.$refs.img3.files[0]);
      body.append("img4", this.$refs.img4.files[0]);
      body.append("img5", this.$refs.img5.files[0]);
      axios
        .post("/api/posts.php", body, {
          header: { "Content-Type": "multipart/form-data" }
        })
        .then(function() {
          window.location.reload(true);
        })
        .catch(err => {
          window.console.log(err);
        });
    },
    showCalendar() {
      window.$("#datepicker-autoclose").datepicker("show");
    },
    toggleFile(e) {
      e.target.parentNode.classList.add("display-none");
      e.target.parentNode.nextSibling.classList.remove("display-none");
    }
  }
};
</script>
