<template>
  <div class="row comment-field">
    <div class="col-lg-12">
      <div class="grid-container">
        <form>
          <div class="row">
            <textarea
              ref="comment"
              rows="5"
              placeholder="コメントを入れてください。"
              class="form-control"
              required
            ></textarea>
          </div>
          <div class="row m-t-10">
            <input
              ref="name"
              placeholder="お名前"
              class="form-control m-r-10"
              required
            />
            <input
              ref="pass"
              type="password"
              placeholder="パスワード"
              class="form-control m-r-10"
            />
            <button
              ref="commentbtn"
              @click="comment($event)"
              class="btn btn-default waves-effect waves-light"
            >
              送信
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import tripcode from "tripcode";

export default {
  props: {
    post: String
  },
  methods: {
    resetbtn() {
      this.$refs.commentbtn.disabled = false;
    },
    comment(e) {
      if (
        this.$refs.comment.checkValidity() &&
        this.$refs.name.checkValidity() &&
        this.$refs.pass.checkValidity()
      ) {
        e.preventDefault();
        let url = window.location.href;
        this.$refs.commentbtn.disabled = true;
        let body = new FormData();
        body.append("function", "POST");
        body.append("post_id", this.post);
        body.append("name", this.$refs.name.value);
        body.append("pass", tripcode(this.$refs.pass.value));
        body.append("comment", this.$refs.comment.value);
        body.append("url", url);
        axios
          .post("/api/comments.php", body)
          .then(() => {
            this.$store.dispatch("reloadComments", this.post);
            if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
              window.location.reload(true);
            }
            this.$refs.name.value = "";
            this.$refs.pass.value = "";
            this.$refs.comment.value = "";
            this.resetbtn();
          })
          .catch(err => {
            window.console.log(err);
          });
      }
    }
  }
};
</script>
