<template>
  <div>
    <common-header />
    <main>
      <div class="col-lg-12">
        <div class="card-box">
          <router-view name="main" />
        </div>
      </div>
    </main>
    <form-modal />
    <common-footer />
  </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import FormModal from "@/components/FormModal.vue";

export default {
  components: {
    CommonHeader,
    CommonFooter,
    FormModal
  }
};
</script>

<style lang="scss">
* {
  font-family: "Noto Sans JP", sans-serif;
}

body,
h2,
p {
  color: #333 !important;
}

.display-none {
  display: none !important;
}

.col-lg-12 {
  float: none;
}

.logo {
  font-size: 18px;
}

main {
  margin-top: 60px;
  margin-bottom: 60px;
  min-height: calc(100vh - 120px);
  padding: 20px 5px 15px 5px;

  .grid-container {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #f4f8fb;
  }

  .item-right {
    .btn {
      margin-top: 20px;
    }
  }

  .item-bottom {
    margin-top: 15px;
    vertical-align: bottom;

    .item-right {
      p {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .navbar .pull-left,
  .navbar .navbar-left {
    float: right !important;
  }
  .navbar-nav {
    clear: both;
  }
  main {
    margin-top: 120px;
    min-height: calc(100vh - 180px);
  }
}

.filter {
  margin-bottom: 20px;

  label {
    font-weight: normal;
  }
}

.pageinfo {
  margin-top: 30px;
  padding-top: 0px;
}

.vue-ads-text-xs {
  font-size: inherit;

  button {
    position: relative;
    padding: 6px 12px;
    line-height: 1.42857143;
    margin-left: -1px;
    color: #636e7b;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      background-color: #e4e7ea;
    }
    &[title="previous"] {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &[title="next"] {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &[title="previous"]:focus,
    &[title="next"]:focus {
      color: #636e7b;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
  .vue-ads-cursor-default.vue-ads-text-white {
    border-color: #052d5d;
    background-color: #052d5d;
    color: #fff;
  }
}

.comment-field {
  textarea {
    width: 40% !important;
  }
}

.radio_container {
  display: inline;
  position: relative;
  padding-left: 30px;
  margin-left: 12px;
  cursor: pointer;
  font-size: 14px;
  line-height: 2em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    input {
      ~ .checkmark {
        background-color: #ccc;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked {
      ~ .checkmark {
        background-color: #2196f3;

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark:after {
    top: 8px;
    left: 8px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
  }
}

.checkmark {
  position: absolute;
  bottom: 3px;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  vertical-align: middle;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
</style>
