<template>
  <div class="form-inline dt-bootstrap no-footer">
    <posts-filter is-admin />
    <div class="row">
      <div class="col-lg-12">
        <table class="table table-striped m-0">
          <thead>
            <tr>
              <th>ステータス</th>
              <th>タイトル</th>
              <th>コメント</th>
              <th>掲載開始</th>
              <th>受付期限</th>
              <th>削除</th>
              <th>編集</th>
            </tr>
          </thead>
          <tbody v-if="!!posts.length">
            <!-- <post-snippet v-for="item in infos" :key="item" :open_var="item.status" :post_id="item.post_id" :title="item.title" :no_of_comments="item.comments" :posted_on="item.postedOn" :valid_till="item.validity" /> -->
            <tr v-for="post in posts" :key="post.id">
              <td v-if="post.status === '1'">オープン</td>
              <td v-else>クローズ</td>
              <td>{{ post.title }}</td>
              <td>{{ post.comments }}</td>
              <td>{{ post.posted_on }}</td>
              <td>{{ post.closed_by }}</td>
              <td>
                <a @click.prevent="deletePost(post.id)" role="button">削除</a>
              </td>
              <td>
                <a
                  @click.prevent="editPost(post.id)"
                  data-toggle="modal"
                  data-target=".form-modal"
                  role="button"
                  >編集</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <posts-pagination v-if="!!posts.length" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import PostsFilter from "@/components/PostsFilter.vue";
import PostsPagination from "@/components/PostsPagination.vue";

export default {
  components: {
    PostsFilter,
    PostsPagination
  },
  computed: mapState(["posts"]),
  methods: {
    deletePost(id) {
      if (confirm("削除しますか？")) {
        this.$store.dispatch("deletePost", id);
      }
    },
    editPost(id) {
      this.$store.commit("post", {});
      this.$store.dispatch("reloadPost", id);
    }
  },
  mounted() {
    this.$store.commit("page", "0");
    this.$store.dispatch("reloadPosts");
  },
  beforeDestroy() {
    this.$store.commit("keyword", "");
    this.$store.commit("page", "0");
    this.$store.commit("posts", []);
    this.$store.commit("post", {});
  }
};
</script>
