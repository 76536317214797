<template>
  <div class="row" id="post-comment">
    <div class="col-lg-12">
      <h2 class="h3">コメント</h2>
      <blockquote v-for="item in comments" :key="item.id">
        <p v-html="item.comment"></p>
        <p class="comment-footer">
          {{ item.name }} ({{ item.posted_on }}, {{ item.ip }})
        </p>
        <div class="input-group">
          <form>
            <input
              :ref="'pass' + item.id"
              type="password"
              placeholder="パスワード"
              class="form-control"
            />
            <span class="input-group-btn">
              <button
                @click="deleteComment($event, item.id)"
                class="btn waves-effect waves-light btn-pink"
              >
                削除
              </button>
            </span>
          </form>
        </div>
      </blockquote>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import tripcode from "tripcode";

export default {
  props: {
    post: String
  },
  computed: {
    comments() {
      const src = this.$store.state.comments,
        dist = [];
      src.forEach(v => {
        v.comment = v.comment.replace(/\n/g, "<br>");
        dist.push(v);
      });
      return dist;
    }
  },
  methods: {
    deleteComment(e, id) {
      const pass = this.$refs["pass" + id][0];

      if (pass.checkValidity()) {
        e.preventDefault();
        let body = new FormData();
        body.append("function", "DELETE");
        body.append("id", id);
        body.append("pass", tripcode(pass.value));
        axios
          .post("/api/comments.php", body)
          .then(() => {
            this.$store.dispatch("reloadComments", this.post);
            if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
              window.location.reload(true);
            }
          })
          .catch(() => {
            window.alert("正しいパスワードを入力してください。");
          });
      }
    }
  }
};
</script>
